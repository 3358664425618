<template>
  <div class="fullheight">
    <div class="content-header flex-row flex-row-between">
      <h1>Filtrar tipos de usuarios</h1>

      <div class="content-header-buttons flex-row">
        <div class="content-header-filter flex-row">
          <i class="icon-arrow-left"></i>
          <span @click="volverAtras">Volver atrás</span>
        </div>
        <button class="btn-primary animated" @click="filtrar">Filtrar</button>
      </div>
    </div>

    <div class="content-content flex-column flex-column-left flex-column-top">
      <h3 class="content-subtit tit-color">COMPLETE LOS FILTROS</h3>
      <div class="space medium-space"></div>
      <form class="form-container fullwidth">

        <div class="flex-row flex-left flex-row-between">

          <div class="flex-column flex-top flex-eqw select-container form-field-indicador-container">
            <label class="form-field-indicador" for="tipos-lugares-filtro-estado"
                   v-show="filtroData.estado !== ''">Estado:</label>
            <select v-model="filtroData.estado" id="tipos-lugares-filtro-estado" name="Estado tipo de lugares" class="form-field"
                    :class="{'field-complete': filtroData.estado !== ''}">
              <option value="">Estado de los tipos de lugares</option>
              <option value="1">Activos</option>
              <option value="0">Desactivos</option>
            </select>
            <i class="icon icon-chevron-down"></i>
          </div>

          <div class="flex-column flex-top flex-eqw select-container form-field-indicador-container">
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus.js'

export default {
  name: 'filtro',
  data () {
    return {
      filtroData: {
        text: '',
        estado: '1'
      }
    }
  },
  methods: {
    volverAtras () {
      this.initFiltrosData()
      this.$emit('mostrarListado')
    },
    filtrar () {
      EventBus.$emit('filtrar', this.filtroData)
      this.$emit('mostrarListado')
    },
    initFiltrosData () {
      this.filtroData = {
        text: '',
        estado: '1'
      }
    }
  },
  mounted () {
    EventBus.$on('actualizarFiltros', (filtrosActualizados) => {
      this.filtroData = filtrosActualizados
    })
  }
}
</script>
