<template>
  <div class="fullheight">
    <div class="content-header flex-row flex-row-between">
      <h1>{{tituloComponente}}</h1>

      <div class="content-header-buttons flex-row">
        <div class="content-header-filter flex-row">
          <i class="icon-arrow-left"></i>
          <span @click="volverAtras">Volver atrás</span>
        </div>
        <button v-if="!tipoLugarSelected" class="btn-primary animated" @click="agregarTipoLugar">Guardar</button>
        <button v-if="tipoLugarSelected && !view" class="btn-primary animated" @click="editar">Guardar</button>
        <button v-if="view" class="btn-primary animated" @click="cambiarAeditar">Editar</button>
      </div>
    </div>

    <div class="content-content flex-column flex-column-left flex-column-top">
      <form class="form-container fullwidth">

        <div class="flex-row flex-left flex-row-between">
          <div class="flex-column flex-top flex-eqw form-field-indicador-container">
            <label class="form-field-indicador" for="tipo-lugar-selected-nombre"
                   v-show="tipoLugar.nombre !== ''">Nombre:</label>
            <input v-model="tipoLugar.nombre" v-validate="'required'" id="tipo-lugar-selected-nombre" name="Nombre" :disabled='view'
                   class="form-field form-field-nomargin" :class="{error: errors.has('Nombre'), 'field-complete': tipoLugar.nombre !== ''}"
                   type="text" placeholder="Nombre" maxlength="30">
          </div>

        </div>

      </form>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus.js'
import ajax from '@/ajax'
import ConstantsHelper from '@/ConstantsHelper'

export default {
  name: 'crearOEditar',
  props: ['view', 'edit', 'agregar', 'tipoLugarSelected'],
  data () {
    return {
      tipoLugar: {
        id: '',
        nombre: '',
        mostrarMenu: false
      }
    }
  },
  methods: {
    volverAtras () {
      EventBus.$emit('toggleShow', true)
      this.initTipoLugar()
      this.$emit('mostrarListado')
    },
    initTipoLugar () {
      this.tipoLugar = {
        id: '',
        nombre: '',
        mostrarMenu: false
      }
    },
    agregarTipoLugar () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('loading', true)

          ajax('POST', ConstantsHelper.getURLs().v1 + 'lugares-tipos.php', { tipoLugar: this.tipoLugar, usuarioLogueado: JSON.parse(localStorage.getItem('usuarioGalponLogueado')) })
            .then((response) => {
              let type = 'error'
              if (response.status === 1) {
                this.tipoLugar.id = response.id_inserted
                this.tipoLugar.state = 1
                EventBus.$emit('tipoLugarAgregado', this.tipoLugar)
                this.$emit('mostrarListado')
                type = 'correct'
                this.initTipoLugar()
              }

              EventBus.$emit('generateToast', {
                message: response.message,
                type: type
              })
              this.$emit('loading', false)
            })
            .catch(() => {
              this.$emit('loading', false)
            })
        }
      })
    },
    editar () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('loading', true)

          ajax('PUT', ConstantsHelper.getURLs().v1 + 'lugares-tipos.php', { tipoLugar: this.tipoLugar, usuarioLogueado: JSON.parse(localStorage.getItem('usuarioGalponLogueado')) })
            .then((response) => {
              let type = 'error'
              if (response.status === 1) {
                this.tipoLugar.mostrarMenu = false
                EventBus.$emit('tipoLugarEditado', this.tipoLugar)
                this.$emit('mostrarListado')
                type = 'correct'
                this.initTipoLugar()
              }

              EventBus.$emit('generateToast', {
                message: response.message,
                type: type
              })

              this.$emit('loading', false)
            })
            .catch(() => {
              this.$emit('loading', false)
            })
        }
      })
    },
    cambiarAeditar () {
      this.$emit('editar', this.tipoLugar)
    },

    toggleHeader () {
      if (this.agregar || this.view || this.edit) {
        EventBus.$emit('toggleShow', false)
      } else {
        EventBus.$emit('toggleShow', true)
      }
    }
  },
  computed: {
    tituloComponente () {
      if (this.view) {
        return 'Visualizar tipo de lugar'
      } else {
        return (this.tipoLugarSelected) ? 'Editar tipo de lugar' : 'Agregar tipo de lugar'
      }
    }
  },
  watch: {
    tipoLugarSelected: {
      handler () {
        if (this.tipoLugarSelected) {
          this.tipoLugar = this.tipoLugarSelected
        } else {
          this.initTipoLugar()
        }
      }
    },
    agregar () {
      this.toggleHeader()
    },
    view () {
      this.toggleHeader()
    },
    edit () {
      this.toggleHeader()
    }
  }
}
</script>
